import { Modal } from "reactstrap";
import { useContext } from "react";
import { useRouter } from "next/router";
import { AuthContext } from "../../../contexts/AuthContext";
import DeactivatedImage from "./DeactivatedImage";
import { jackColors } from "../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../Text";
import { ButtonJack } from "../../ButtonsJack/parent";
import styles from "./styles.module.css";

const DeactivatedUserModal = ({ isOpen = false }) => {
  const { unauthorize } = useContext(AuthContext);

  const { push } = useRouter();

  const handleClickButton = () => {
    unauthorize();
    push("/login");
  };

  return (
    <Modal centered isOpen={isOpen} style={{ width: "480px" }}>
      <div className={styles["deactivated-user-modal"]}>
        <DeactivatedImage />
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          You do not have access to this page
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          Your admin has restricted your access to the page.
          <br /> Please contact your admin.
        </GothamRegular>
        <ButtonJack
          style={{ width: "100%", marginTop: "32px" }}
          onClick={handleClickButton}
        >
          Log Out
        </ButtonJack>
      </div>
    </Modal>
  );
};

export default DeactivatedUserModal;
