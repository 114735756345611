import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import styles from "../../styles.module.css";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useContext } from "react";
import { useRouter } from "next/router";
import { useSubscriptionUsage } from "../../logics/general-hooks";
import NoSeatImage from "./NoSeatImage";
import { useTranslation } from "react-i18next";

const NoSeatModal = ({ isOpen = false, number = 0, toggle = () => {} }) => {
  const { t } = useTranslation("common")
  const { unauthorize } = useContext(AuthContext);
  const { max_users } = useSubscriptionUsage();
  const { push } = useRouter();

  const handleClickButton = () => {
    unauthorize();
    push("/login");
  };

  return (
    <Modal centered isOpen={isOpen} style={{ width: "480px" }}>
      <div className={styles["prevention-modal"]}>
        <NoSeatImage />
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          {t("There’s no available seat left")}
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          {t("Your company has reached a maximum of")}{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            {max_users ?? 0} {t("users")}
          </span>
          . {t("Please contact your admin for help.")}
        </GothamRegular>
        <ButtonJack
          style={{ width: "100%", marginTop: "32px" }}
          onClick={handleClickButton}
        >
          {t("Log Out")}
        </ButtonJack>
      </div>
    </Modal>
  );
};

export default NoSeatModal;
