import { useRouter } from "next/router";
import { createContext, useEffect, useState } from "react";
import { useModalHook } from "../../components/Modals";
import DeactivatedUserModal from "../../components/Modals/DeactivatedUserModal/parent";
import { windowDimension } from "../../components/tools";
import { CategoryIntroModal } from "../../pageComponents/categoryComponents/general/components";
import { useCategoryFlag } from "../../pageComponents/categoryComponents/general/hooks";
import NoSeatModal from "../../pageComponents/subscriptionsJackComponents/general-components/NoSeatModal/parent";
import SubscriptionNotificationModal from "../../pageComponents/subscriptionsJackComponents/general-components/NotificationModal/parent";
import OverdueModal from "../../pageComponents/subscriptionsJackComponents/general-components/OverdueModal/parent";
import SuspendedModal from "../../pageComponents/subscriptionsJackComponents/general-components/SuspendedModal/parent";
import { useSubscriptionUsage } from "../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { useGetAuth, getUserRole } from "../AuthContext";
import { useVaFlag } from "../../pageComponents/virtualAccountComponents/common/hooks";
import VAInfoModal from "../../pageComponents/virtualAccountComponents/common/onboardingModals";

export const AlertContext = createContext();

export const AlertContextProvider = ({ children }) => {
  // const [showedCategoryIntro, setShowedCategoryIntro] = useState(false);
  const [showedVaIntro, setShowedVaIntro] = useState(false);
  const { pathname } = useRouter();

  const { user, userLoading } = useGetAuth();
  const { status, subs_notif_is_read, all_flags } = user || {};

  const { hasIntroducedCategory } = useCategoryFlag();
  const { hasIntroducedVa } = useVaFlag();

  const { isTabOrPhone } = windowDimension();

  const { isOverdue, isSuspended, loadingSubscriptionUsage, isUltimate } =
    useSubscriptionUsage();

  const { isEmployee } = getUserRole();

  const {
    isOpen: isOpenMobileAlertModal,
    toggle: toggleMobileAlertModal,
    open: openMobileAlertModal,
  } = useModalHook();

  const {
    isOpen: isOpenNoSeatModal,
    open: openNoSeatModal,
    close: closeNoSeatModal,
  } = useModalHook();

  const {
    isOpen: isOpenDeactivateduserModal,
    open: openDeactivateduserModal,
    close: closeDeactivateduserModal,
  } = useModalHook();

  const {
    isOpen: isOpenSubsNotifModal,
    open: openSubsNotifModal,
    close: closeSubsNotifModal,
  } = useModalHook();

  const {
    isOpen: isOpenSuspendedModal,
    open: openSuspendedModal,
    close: closeSuspendedModal,
  } = useModalHook();

  const {
    isOpen: isOpenOverdueModal,
    open: openOverdueModal,
    close: closeOverdueModal,
  } = useModalHook();

  // const {
  //   isOpen: isOpenCategoryIntroModal,
  //   open: openCategoryIntroModal,
  //   close: closeCategoryIntroModal,
  // } = useModalHook();

  // const {
  //   isOpen: isOpenVaIntroModal,
  //   open: openVaIntroModal,
  //   close: closeVaIntroModal,
  // } = useModalHook();

  // const handleShowCategoryIntro = () => {
  //   setShowedCategoryIntro(true);
  //   openCategoryIntroModal();
  // };

  // const handleShowVaIntro = () => {
  //   if (isEmployee) return;
  //   setShowedVaIntro(true);
  //   openVaIntroModal();
  // };

  useEffect(() => {
    const isUnseated = status === "pending";
    const isBlocked = status === "blocked";
    const isLoading = loadingSubscriptionUsage || userLoading
    const notifFromSubscription = subs_notif_is_read && !isLoading && !isUltimate;
    // const showIntroCategory =
    //   !hasIntroducedCategory &&
    //   pathname === "/dashboard" &&
    //   !showedCategoryIntro;

    const showIntroVa =
      !hasIntroducedVa &&
      pathname === "/dashboard" &&
      !showedVaIntro &&
      !isEmployee;

    const excludedPathnames = ["/login", "/register", "/create-password"];

    if (excludedPathnames.includes(pathname)) {
      closeSubsNotifModal();
      closeNoSeatModal();
      closeSuspendedModal();
      closeOverdueModal();
      closeDeactivateduserModal();
      return;
    }

    if (!status || userLoading || loadingSubscriptionUsage) return;
    if (isUnseated) return openNoSeatModal();
    if (isBlocked) return openDeactivateduserModal();
    if (notifFromSubscription) return openSubsNotifModal();
    // if (showIntroCategory) return handleShowCategoryIntro();
    // if (showIntroVa) return handleShowVaIntro();
    if (!notifFromSubscription) return closeSubsNotifModal();
  }, [
    status,
    subs_notif_is_read,
    pathname,
    all_flags,
    userLoading,
    // showedCategoryIntro,
    hasIntroducedCategory,
    loadingSubscriptionUsage
  ]);

  useEffect(() => {
    if (!isTabOrPhone) return;
    openMobileAlertModal();
  }, [isTabOrPhone]);

  useEffect(() => {
    const excludedPathnames = ["/login", "/register", "/create-password"];

    if (loadingSubscriptionUsage || excludedPathnames.includes(pathname))
      return;

    const showed = JSON.parse(
      localStorage.getItem("showed_suspend_or_overdue_modal")
    );

    if (!showed) {
      localStorage.setItem("showed_suspend_or_overdue_modal", true);
      if (isSuspended) return openSuspendedModal();
      if (isOverdue) return openOverdueModal();
    }
  }, [isSuspended, loadingSubscriptionUsage, pathname]);

  return (
    <AlertContext.Provider value={{}}>
      {/* {isTabOrPhone && (
        <MobileAlertModal
          isOpen={isOpenMobileAlertModal}
          toggle={toggleMobileAlertModal}
        />
      )} */}
      <NoSeatModal isOpen={isOpenNoSeatModal} />
      <SubscriptionNotificationModal
        isOpen={isOpenSubsNotifModal}
        toggle={closeSubsNotifModal}
      />
      <OverdueModal isOpen={isOpenOverdueModal} toggle={closeOverdueModal} />
      <SuspendedModal
        isOpen={isOpenSuspendedModal}
        toggle={closeSuspendedModal}
      />
      <DeactivatedUserModal isOpen={isOpenDeactivateduserModal} />
      {/*
      <CategoryIntroModal
        isOpen={isOpenCategoryIntroModal}
        toggle={closeCategoryIntroModal}
      />
        */}
      {/*  // disable va onboarding modal after login
      <VAInfoModal
        isOpen={isOpenVaIntroModal}
        toggle={closeVaIntroModal}
        open={openVaIntroModal}
        isHome
      /> */}
      {children}
    </AlertContext.Provider>
  );
};
