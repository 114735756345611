import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import styles from "../../styles.module.css";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import SuspendedImage from "./SuspendedImage";

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button className={styles["close-btn"]} onClick={onClick}>
      <JackIcons name="close-outline" fill={jackColors.neutral900} />
    </button>
  );
};

const SuspendedModal = ({ isOpen = false, toggle = () => {} }) => {
  return (
    <Modal centered isOpen={isOpen} toggle={toggle} style={{ width: "480px" }}>
      <div className={styles["prevention-modal"]}>
        <CloseButton onClick={toggle} />
        <SuspendedImage />
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          Your account has been suspended
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          As we have not received payment for your last bill, you are no longer
          can access some of the services. Please contact your Account Manager
          for details.
        </GothamRegular>
        <ButtonJack
          style={{ width: "100%", marginTop: "32px" }}
          onClick={toggle}
        >
          I understand
        </ButtonJack>
      </div>
    </Modal>
  );
};

export default SuspendedModal;
