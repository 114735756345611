import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { fetch } from "../../../tools/api";
import { AuthContext, useGetAuth } from "../../../contexts/AuthContext";

export const useSubscriptionUsage = () => {
  const { subscription } = useContext(AuthContext);
  return subscription;
};

export const useSubscriptionBillings = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { user } = useGetAuth();

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/bills",
    params: { page },
    woInit: true,
    formatter: (res, prev) => {
      if (page === 1) return res;
      return { ...prev, data: [...prev.data, ...res.data] };
    },
    afterSuccess: (res) => setTotalPage(res.total_page),
  });

  useEffect(() => {
    if (isEmpty(user)) return;
    if (page <= totalPage) refetch();
  }, [page, totalPage, isEmpty(user)]);

  const billings = data.data ?? [];
  const dueDate = data.due_date ?? billings[0]?.due_date ?? new Date();
  const billingEmail = data.bill_email ?? "";
  const costBreakdown = data.cost_breakdown ?? {};
  const totalFreeFeeLocalTransfer =
    data?.total_free_qty?.total_free_local_transfer_quantity ?? 0;

  return {
    dueDate,
    billings,
    billingEmail,
    costBreakdown,
    loading,
    page,
    totalPage,
    totalFreeFeeLocalTransfer,
    setPage,
    cleanRefetch: async () => {
      if (isEmpty(user)) return;
      if (page === 1) return refetch();
      setPage(1);
    },
  };
};

export const useSubscriptionTemplates = () => {
  const { user } = useGetAuth();

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/templates",
    defaultValue: {},
    woInit: true,
  });

  const rules = (planType = "seed") => {
    const templates = data?.data;
    if (!templates) return {};
    return (
      (templates || []).find(({ plan_type }) => plan_type === planType) ?? {}
    );
  };

  const seedPlanRules = rules("seed");
  const growthPlanRules = rules("growth");
  const customPlanRules = rules("long term");

  useEffect(() => {
    if (!isEmpty(user)) refetch();
  }, [!isEmpty(user)]);

  return { loading, seedPlanRules, growthPlanRules, customPlanRules };
};
