const NoSeatImage = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.78 189.13V52.6503C12.78 47.8903 16.64 44.0303 21.4 44.0303C23.78 44.0303 25.93 44.9903 27.49 46.5503C29.05 48.1103 30.02 50.2703 30.02 52.6503V189.13"
        fill="white"
      />
      <path
        d="M30.01 189.62C29.74 189.62 29.52 189.4 29.52 189.13V52.65C29.52 50.48 28.67 48.43 27.14 46.9C25.61 45.37 23.56 44.52 21.39 44.52C16.91 44.52 13.26 48.17 13.26 52.65V189.13C13.26 189.4 13.04 189.62 12.77 189.62C12.5 189.62 12.28 189.4 12.28 189.13V52.65C12.28 47.63 16.36 43.54 21.38 43.54C23.81 43.54 26.1 44.49 27.82 46.21C29.54 47.93 30.49 50.22 30.49 52.65V189.13C30.5 189.4 30.28 189.62 30.01 189.62Z"
        fill="black"
      />
      <path
        d="M169.99 189.13V52.6503C169.99 47.8903 173.85 44.0303 178.61 44.0303C180.99 44.0303 183.14 44.9903 184.7 46.5503C186.26 48.1103 187.22 50.2703 187.22 52.6503V189.13"
        fill="white"
      />
      <path
        d="M187.22 189.62C186.95 189.62 186.73 189.4 186.73 189.13V52.65C186.73 50.48 185.88 48.43 184.35 46.9C182.82 45.37 180.77 44.52 178.6 44.52C174.12 44.52 170.47 48.17 170.47 52.65V189.13C170.47 189.4 170.25 189.62 169.98 189.62C169.71 189.62 169.49 189.4 169.49 189.13V52.65C169.49 47.63 173.58 43.54 178.6 43.54C181.03 43.54 183.32 44.49 185.04 46.21C186.76 47.93 187.71 50.22 187.71 52.65V189.13C187.71 189.4 187.49 189.62 187.22 189.62Z"
        fill="black"
      />
      <path
        d="M194.61 56.2002V102.51C194.61 103.74 193.61 104.74 192.38 104.74H7.62001C6.39001 104.74 5.39001 103.74 5.39001 102.51V56.2002C5.39001 54.9702 6.39001 53.9702 7.62001 53.9702H192.38C193.61 53.9702 194.61 54.9702 194.61 56.2002Z"
        fill="white"
      />
      <path
        d="M54.45 53.9702L13.25 104.75H7.62001C6.39001 104.75 5.39001 103.75 5.39001 102.52V92.6802L36.79 53.9802H54.45V53.9702Z"
        fill="#B9FC00"
      />
      <path
        d="M102.82 53.9702L61.61 104.75H43.95L85.16 53.9702H102.82Z"
        fill="#B9FC00"
      />
      <path
        d="M151.18 53.9702L109.98 104.75H92.31L133.52 53.9702H151.18Z"
        fill="#B9FC00"
      />
      <path
        d="M194.61 56.2002V60.0502L158.34 104.75H140.68L181.88 53.9702H192.37C193.61 53.9702 194.61 54.9702 194.61 56.2002Z"
        fill="#B9FC00"
      />
      <path
        d="M192.38 105.24H7.62002C6.12002 105.24 4.90002 104.02 4.90002 102.52V56.2C4.90002 54.7 6.12002 53.48 7.62002 53.48H192.38C193.88 53.48 195.1 54.7 195.1 56.2V102.51C195.1 104.02 193.88 105.24 192.38 105.24ZM7.62002 54.46C6.66002 54.46 5.87002 55.24 5.87002 56.21V102.52C5.87002 103.48 6.65002 104.27 7.62002 104.27H192.38C193.34 104.27 194.13 103.49 194.13 102.52V56.2C194.13 55.24 193.35 54.45 192.38 54.45H7.62002V54.46Z"
        fill="black"
      />
      <path
        d="M36.6 196.661H6.19C6.19 192.461 9.59 189.061 13.79 189.061H29C33.19 189.061 36.6 192.461 36.6 196.661Z"
        fill="white"
      />
      <path
        d="M37.08 197.15H5.71002V196.66C5.71002 192.2 9.34002 188.57 13.8 188.57H29C33.46 188.57 37.09 192.2 37.09 196.66V197.15H37.08ZM6.70002 196.17H36.09C35.84 192.47 32.75 189.54 28.99 189.54H13.79C10.04 189.55 6.95002 192.48 6.70002 196.17Z"
        fill="black"
      />
      <path
        d="M193.81 196.661H163.41C163.41 192.461 166.81 189.061 171.01 189.061H186.21C190.4 189.061 193.81 192.461 193.81 196.661Z"
        fill="white"
      />
      <path
        d="M194.29 197.15H162.91V196.66C162.91 192.2 166.54 188.57 171 188.57H186.2C190.66 188.57 194.29 192.2 194.29 196.66V197.15ZM163.91 196.17H193.3C193.05 192.47 189.96 189.54 186.2 189.54H171C167.25 189.55 164.16 192.48 163.91 196.17Z"
        fill="black"
      />
      <path
        d="M103.91 76.3003H66.45C63.34 76.3003 61.4 72.9403 62.95 70.2403L81.68 37.8003L100.41 5.36027C101.96 2.67027 105.85 2.67027 107.4 5.36027L126.13 37.8003L144.86 70.2403C146.41 72.9303 144.47 76.3003 141.36 76.3003H103.91Z"
        fill="white"
      />
      <path
        d="M141.37 76.7901H66.45C64.81 76.7901 63.35 75.9401 62.53 74.5301C61.71 73.1101 61.71 71.4201 62.53 70.0101L99.99 5.1101C100.81 3.6901 102.27 2.8501 103.91 2.8501C105.55 2.8501 107.01 3.7001 107.83 5.1101L145.29 70.0001C146.11 71.4201 146.11 73.1101 145.29 74.5201C144.47 75.9401 143.01 76.7901 141.37 76.7901ZM103.91 3.8301C102.63 3.8301 101.48 4.4901 100.84 5.6001L63.38 70.4901C62.74 71.6001 62.74 72.9301 63.38 74.0401C64.02 75.1501 65.17 75.8101 66.45 75.8101H141.37C142.65 75.8101 143.8 75.1501 144.44 74.0401C145.08 72.9301 145.08 71.6001 144.44 70.4901L106.99 5.6001C106.34 4.4901 105.19 3.8301 103.91 3.8301Z"
        fill="black"
      />
      <path
        d="M107.62 60.3801C107.62 62.4001 105.99 64.0301 103.97 64.0301H103.86C101.84 64.0301 100.21 62.4001 100.21 60.3801C100.21 58.3601 101.84 56.7301 103.86 56.7301H103.97C105.98 56.7301 107.62 58.3601 107.62 60.3801ZM103.91 24.5801C105.93 24.5801 107.56 26.2101 107.56 28.2301V31.9401L106.01 50.0901C105.92 51.1501 105.03 51.9701 103.96 51.9701C102.9 51.9701 102.01 51.1601 101.91 50.1001L100.25 31.9401V28.2301C100.26 26.2201 101.9 24.5801 103.91 24.5801Z"
        fill="#B9FC00"
      />
    </svg>
  );
};

export default NoSeatImage;
