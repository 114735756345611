import { useGetAuth } from "../../../../contexts/AuthContext";
import { useRouter } from "next/router";
import { useMutation } from "../../../../tools/api";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import styles from "../../styles.module.css";
import WaitingPersonImage from "./WaitingPersonImage";
import { Modal } from "reactstrap";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { jackColors } from "../../../../assets/colors";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import GrowthPlanActiveImage from "./GrowthPlanAcitveImage";

const mainTextDecider = (type = "") => {
  switch (type) {
    case "upgrade":
      return "Growth plan now activated!";
    case "downgrade":
      return "Seed plan now activated!";
    default:
      return "";
  }
};
const secondaryTextDecider = (type = "") => {
  switch (type) {
    case "upgrade":
      return "Enjoy your unlimited users, lower fees, boundless transactions, and many more!";
    case "downgrade":
      return "By downgrading your account, you can no longer enjoy Growth Plan benefits. Kindly check the Seed and Growth Plan comparison here.";
    default:
      return "";
  }
};
const imageDecider = (type = "") => {
  switch (type) {
    case "upgrade":
      return <GrowthPlanActiveImage />;
    case "downgrade":
      return <WaitingPersonImage />;
    default:
      return "";
  }
};
const buttonTextDecider = (type = "") => {
  switch (type) {
    case "upgrade":
      return "Okay";
    case "downgrade":
      return "Compare Plans";
    default:
      return "";
  }
};

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button className={styles["close-btn"]} onClick={onClick}>
      <JackIcons name="close-outline" fill={jackColors.neutral900} />
    </button>
  );
};

const SubscriptionNotificationModal = ({
  isOpen = false,
  toggle = () => {},
}) => {
  const { push } = useRouter();

  const { user, refetchUser } = useGetAuth();
  const { subs_notif_is_read } = user || {};

  const isDowngrade = subs_notif_is_read === "downgrade";

  const { mutation } = useMutation({
    url: "/business_users/subs_notification",
    afterSuccess: () => {
      refetchUser();
    },
  });

  const handleClickButton = () => {
    toggle();
    mutation();

    if (isDowngrade) push("/plans/showcase");
  };

  const handleClose = () => {
    toggle();
    mutation();
  };

  const mainText = mainTextDecider(subs_notif_is_read);
  const secondaryText = secondaryTextDecider(subs_notif_is_read);
  const image = imageDecider(subs_notif_is_read);
  const buttonText = buttonTextDecider(subs_notif_is_read);

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={handleClose}
      style={{ width: "480px" }}
    >
      <div className={styles["prevention-modal"]}>
        <CloseButton onClick={handleClose} />
        {image}
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          {mainText}
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          {secondaryText}
        </GothamRegular>
        <ButtonJack
          style={{ width: "100%", marginTop: "32px" }}
          onClick={handleClickButton}
        >
          {buttonText}
        </ButtonJack>
      </div>
    </Modal>
  );
};

export default SubscriptionNotificationModal;
