const DeactivatedImage = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: "32px" }}
    >
      <path
        d="M12.7798 189.13V52.6503C12.7798 47.8903 16.6398 44.0303 21.3998 44.0303C23.7798 44.0303 25.9298 44.9903 27.4898 46.5503C29.0498 48.1103 30.0198 50.2703 30.0198 52.6503V189.13"
        fill="white"
      />
      <path
        d="M30.0098 189.62C29.7398 189.62 29.5198 189.4 29.5198 189.13V52.65C29.5198 50.48 28.6698 48.43 27.1398 46.9C25.6098 45.37 23.5598 44.52 21.3898 44.52C16.9098 44.52 13.2598 48.17 13.2598 52.65V189.13C13.2598 189.4 13.0398 189.62 12.7698 189.62C12.4998 189.62 12.2798 189.4 12.2798 189.13V52.65C12.2798 47.63 16.3598 43.54 21.3798 43.54C23.8098 43.54 26.0998 44.49 27.8198 46.21C29.5398 47.93 30.4898 50.22 30.4898 52.65V189.13C30.4998 189.4 30.2798 189.62 30.0098 189.62Z"
        fill="black"
      />
      <path
        d="M169.99 189.13V52.6503C169.99 47.8903 173.85 44.0303 178.61 44.0303C180.99 44.0303 183.14 44.9903 184.7 46.5503C186.26 48.1103 187.22 50.2703 187.22 52.6503V189.13"
        fill="white"
      />
      <path
        d="M187.22 189.62C186.95 189.62 186.73 189.4 186.73 189.13V52.65C186.73 50.48 185.88 48.43 184.35 46.9C182.82 45.37 180.77 44.52 178.6 44.52C174.12 44.52 170.47 48.17 170.47 52.65V189.13C170.47 189.4 170.25 189.62 169.98 189.62C169.71 189.62 169.49 189.4 169.49 189.13V52.65C169.49 47.63 173.58 43.54 178.6 43.54C181.03 43.54 183.32 44.49 185.04 46.21C186.76 47.93 187.71 50.22 187.71 52.65V189.13C187.71 189.4 187.49 189.62 187.22 189.62Z"
        fill="black"
      />
      <path
        d="M194.61 56.1997V102.51C194.61 103.74 193.61 104.74 192.38 104.74H7.61989C6.38989 104.74 5.38989 103.74 5.38989 102.51V56.1997C5.38989 54.9697 6.38989 53.9697 7.61989 53.9697H192.38C193.61 53.9697 194.61 54.9697 194.61 56.1997Z"
        fill="white"
      />
      <path
        d="M54.4499 53.9697L13.2499 104.75H7.61989C6.38989 104.75 5.38989 103.75 5.38989 102.52V92.6797L36.7899 53.9797H54.4499V53.9697Z"
        fill="#B9FC00"
      />
      <path
        d="M102.82 53.9697L61.61 104.75H43.95L85.16 53.9697H102.82Z"
        fill="#B9FC00"
      />
      <path
        d="M151.18 53.9697L109.98 104.75H92.3098L133.52 53.9697H151.18Z"
        fill="#B9FC00"
      />
      <path
        d="M194.61 56.1997V60.0497L158.34 104.75H140.68L181.88 53.9697H192.37C193.61 53.9697 194.61 54.9697 194.61 56.1997Z"
        fill="#B9FC00"
      />
      <path
        d="M192.38 105.239H7.6199C6.1199 105.239 4.8999 104.019 4.8999 102.519V56.1995C4.8999 54.6995 6.1199 53.4795 7.6199 53.4795H192.38C193.88 53.4795 195.1 54.6995 195.1 56.1995V102.509C195.1 104.019 193.88 105.239 192.38 105.239ZM7.6199 54.4595C6.6599 54.4595 5.8699 55.2395 5.8699 56.2095V102.519C5.8699 103.479 6.6499 104.269 7.6199 104.269H192.38C193.34 104.269 194.13 103.489 194.13 102.519V56.1995C194.13 55.2395 193.35 54.4495 192.38 54.4495H7.6199V54.4595Z"
        fill="black"
      />
      <path
        d="M36.5999 196.66H6.18994C6.18994 192.46 9.58994 189.06 13.7899 189.06H28.9999C33.1899 189.06 36.5999 192.46 36.5999 196.66Z"
        fill="white"
      />
      <path
        d="M37.08 197.149H5.70996V196.659C5.70996 192.199 9.33996 188.569 13.8 188.569H29C33.46 188.569 37.09 192.199 37.09 196.659V197.149H37.08ZM6.69996 196.169H36.09C35.84 192.469 32.75 189.539 28.99 189.539H13.79C10.04 189.549 6.94996 192.479 6.69996 196.169Z"
        fill="black"
      />
      <path
        d="M193.81 196.66H163.41C163.41 192.46 166.81 189.06 171.01 189.06H186.21C190.4 189.06 193.81 192.46 193.81 196.66Z"
        fill="white"
      />
      <path
        d="M194.29 197.149H162.91V196.659C162.91 192.199 166.54 188.569 171 188.569H186.2C190.66 188.569 194.29 192.199 194.29 196.659V197.149ZM163.91 196.169H193.3C193.05 192.469 189.96 189.539 186.2 189.539H171C167.25 189.549 164.16 192.479 163.91 196.169Z"
        fill="black"
      />
      <path
        d="M103.91 76.2993H66.4498C63.3398 76.2993 61.3998 72.9393 62.9498 70.2393L81.6798 37.7993L100.41 5.3593C101.96 2.6693 105.85 2.6693 107.4 5.3593L126.13 37.7993L144.86 70.2393C146.41 72.9293 144.47 76.2993 141.36 76.2993H103.91Z"
        fill="white"
      />
      <path
        d="M141.37 76.7896H66.4498C64.8098 76.7896 63.3498 75.9396 62.5298 74.5296C61.7098 73.1096 61.7098 71.4196 62.5298 70.0096L99.9898 5.10961C100.81 3.68961 102.27 2.84961 103.91 2.84961C105.55 2.84961 107.01 3.69961 107.83 5.10961L145.29 69.9996C146.11 71.4196 146.11 73.1096 145.29 74.5196C144.47 75.9396 143.01 76.7896 141.37 76.7896ZM103.91 3.82961C102.63 3.82961 101.48 4.48961 100.84 5.59961L63.3798 70.4896C62.7398 71.5996 62.7398 72.9296 63.3798 74.0396C64.0198 75.1496 65.1698 75.8096 66.4498 75.8096H141.37C142.65 75.8096 143.8 75.1496 144.44 74.0396C145.08 72.9296 145.08 71.5996 144.44 70.4896L106.99 5.59961C106.34 4.48961 105.19 3.82961 103.91 3.82961Z"
        fill="black"
      />
      <path
        d="M107.62 60.3801C107.62 62.4001 105.99 64.0301 103.97 64.0301H103.86C101.84 64.0301 100.21 62.4001 100.21 60.3801C100.21 58.3601 101.84 56.7301 103.86 56.7301H103.97C105.98 56.7301 107.62 58.3601 107.62 60.3801ZM103.91 24.5801C105.93 24.5801 107.56 26.2101 107.56 28.2301V31.9401L106.01 50.0901C105.92 51.1501 105.03 51.9701 103.96 51.9701C102.9 51.9701 102.01 51.1601 101.91 50.1001L100.25 31.9401V28.2301C100.26 26.2201 101.9 24.5801 103.91 24.5801Z"
        fill="#B9FC00"
      />
    </svg>
  );
};

export default DeactivatedImage;
