import styles from "../../categoryComponents/general/components/CategoryInfoModal/CategoryInfoModal.module.css";
import { jackColors } from "../../../assets/colors";
import { GothamBold, GothamRegular } from "../../../components/Text";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import SimpleCenteredModal from "../../../modals/SimpleCenteredModal";
import { categoryInfoTextDecider } from "../../categoryComponents/general/helpers";
import NextImage from "../../../components/NextImage";
import { useRouter } from "next/router";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../assets/jackIcons/parent";
import infoImg from "../../../assets/images/va-info-image.svg";
import { useVaFlag } from "./hooks";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { eventsTracker } from "../../../universalFunctions/events";
import { Trans, useTranslation } from "react-i18next";

const VAInfoModal = ({
  isOpen = false,
  toggle = () => {},
  open,
  isHome = false,
}) => {
  const { user } = useGetAuth();
  const { all_flags } = user || [];
  const { push, pathname } = useRouter();
  const { isEmployee } = getUserRole();
  const { vaFlags, addVaFlag, hasIntroducedVa, hasSeenVaPage } = useVaFlag();
  const isFirstTime = !(all_flags || []).includes(vaFlags?.hasSeenVaPage);
  const { t } = useTranslation("va/va");

  const text = (type, firstTime = false) => {
    switch (type) {
      case "title":
        if (isHome) return t("Receive payments on behalf of your company!");
        if (firstTime) return t("Welcome to Virtual Account page!");
        return t("Receive payment with Virtual Account");

      case "first":
        if (isHome)
          return t(
            "Now you can create virtual accounts and share them to receive payments. All the cash will be safely stored in your Company Balance."
          );
        if (firstTime)
          return t(
            "Here you can create virtual accounts from various banks, manage your existing virtual accounts, and monitor received payments in real-time."
          );
        return t(
          "Virtual account will help you to receive payment more efficiently. Just share your virtual account number and let people easily send money to you."
        );

      case "second":
        if (isHome)
          return (
            <>
              {t("Ready to dive in? Open Virtual Account page.")}
              {/* or read{" "}
              <span
                style={{
                  fontFamily: "GothamMedium",
                  textDecoration: "underline",
                }}
              >
                this article.
              </span> */}
            </>
          );
        if (firstTime)
          return t(
            "Let’s start by creating your first virtual account, shall we?"
          );
      // return (
      //   <>
      //     <Trans
      //       i18nKey={t("dynamic.modal_text_2")}
      //       components={[
      //         <span
      //           style={{
      //             fontFamily: "GothamMedium",
      //             textDecoration: "underline",
      //           }}
      //         />,
      //       ]}
      //     />
      //   </>
      // );
      default:
        break;
    }
  };

  const handleMainButtonClick = () => {
    if (isHome) {
      toggle();
      eventsTracker("virtual-account-first-time-onboarding-completed");
      push("/virtual-account");
      return addVaFlag(vaFlags.hasIntroducedVa);
    }
    return toggle();
  };

  const handleCloseButtonClick = () => {
    if (isHome) {
      eventsTracker("virtual-account-first-time-onboarding-completed");
      addVaFlag(vaFlags.hasIntroducedVa);
      return toggle();
    }
    eventsTracker("virtual-account-first-time-page-visit-completed");
    toggle();
    return addVaFlag(vaFlags.hasSeenVaPage);
  };

  const handleGoToCreate = () => {
    eventsTracker("virtual-account-first-time-page-visit-completed");
    push("/virtual-account/create");
    return addVaFlag(vaFlags.hasSeenVaPage);
  };

  const fontStyle = {
    woFontColor: false,
    style: { color: jackColors.neutral800 },
  };

  useEffect(() => {
    if (isEmpty(user) || !user) return;
    if (isEmployee) return;
    const isVaPage = pathname === "/virtual-account" && !isHome;
    const isDashboardPage = pathname === "/dashboard" && isHome;

    if (isVaPage) {
      if (hasSeenVaPage) return;
      return open();
    }

    if (isDashboardPage) {
      if (hasIntroducedVa) return;
      return open();
    }
  }, [user, pathname, hasIntroducedVa, hasSeenVaPage]);

  if (isEmployee) return null;

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      toggle={() => handleCloseButtonClick()}
      buttonText={isHome ? t("Go to Virtual Account") : t("Got it!")}
      onClick={() => handleMainButtonClick()}
      modalClassName={styles["modal"]}
      leftHeaderComponent={
        isHome ? (
          <GothamBold style={{ fontSize: "20px", margin: 0 }}>
            {t("Introducing new feature : Virtual Account")}
          </GothamBold>
        ) : null
      }
      customButtons={
        isFirstTime && !isHome ? (
          <div
            style={{
              display: "flex",
              gap: 16,
              width: "100%",
            }}
          >
            <ButtonJack
              type="outline"
              onClick={() => handleCloseButtonClick()}
              style={{ width: "100%" }}
            >
              {t("I’ll do it later")}
            </ButtonJack>
            <ButtonJack
              onClick={() => handleGoToCreate()}
              style={{ width: "100%" }}
              rightIcon={
                <JackIcons name="arrow-forward" fill={jackColors.neutral900} />
              }
            >
              {t("Create Virtual Account")}
            </ButtonJack>
          </div>
        ) : null
      }
    >
      <div className={styles["content-container"]}>
        <div>
          <NextImage
            width={476}
            height={200}
            src={infoImg}
            alt="VA Info Image"
          />
          <GothamBold woFontSize className="font20">
            {text("title", isFirstTime)}
          </GothamBold>
        </div>
        <div>
          <GothamRegular {...fontStyle}>
            {text("first", isFirstTime)}
          </GothamRegular>
          <GothamRegular {...fontStyle}>
            {text("second", isFirstTime)}
          </GothamRegular>
          <GothamRegular {...fontStyle}>
            {text("third", isFirstTime)}
          </GothamRegular>
        </div>
      </div>
    </SimpleCenteredModal>
  );
};

export default VAInfoModal;
