import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import styles from "../../styles.module.css";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { useRouter } from "next/router";
import {
  useSubscriptionBillings,
  useSubscriptionUsage,
} from "../../logics/general-hooks";
import OverdueImage from "./OverdueImage";
import moment from "moment";

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button className={styles["close-btn"]} onClick={onClick}>
      <JackIcons name="close-outline" fill={jackColors.neutral900} />
    </button>
  );
};

const OverdueModal = ({ isOpen = false, toggle = () => {} }) => {
  const { dueDate } = useSubscriptionBillings();

  const { push } = useRouter();

  const handleClose = () => toggle();

  const handleClick = async () => {
    await push("/plans");
    handleClose();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={handleClose}
      style={{ width: "480px" }}
    >
      <div className={styles["prevention-modal"]}>
        <CloseButton onClick={handleClose} />
        <OverdueImage />
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          Your plan is overdue
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          It appears that your plan renewal was due on{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            {moment(dueDate).format("LL")}
          </span>
          . Please make payment to the account listed on your invoice.
        </GothamRegular>
        <ButtonJack
          style={{ width: "100%", marginTop: "32px" }}
          onClick={handleClick}
        >
          Go to Billing Page
        </ButtonJack>
      </div>
    </Modal>
  );
};

export default OverdueModal;
