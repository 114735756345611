const OverdueImage = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M192.98 91.1697H170.03C170.59 95.0897 170.88 99.0897 170.88 103.16C170.88 149.54 133.15 187.27 86.78 187.27C40.41 187.27 2.67999 149.54 2.67999 103.16C2.67999 56.7797 40.41 19.0597 86.78 19.0597C105.56 19.0597 122.93 25.2497 136.94 35.6997L148.94 14.9097C150.61 12.0197 154.79 12.0197 156.46 14.9097L176.59 49.7897L196.73 84.6697C198.4 87.5497 196.32 91.1697 192.98 91.1697Z"
        fill="white"
      />
      <path
        d="M170.03 91.1698C168.12 77.8298 163.07 65.4898 155.65 54.9298C153.63 52.0498 151.43 49.3098 149.07 46.7098C145.4 42.6598 141.33 38.9598 136.94 35.6898C122.92 25.2398 105.56 19.0498 86.78 19.0498C40.4 19.0498 2.67999 56.7798 2.67999 103.15C2.67999 149.52 40.41 187.26 86.78 187.26C133.15 187.26 170.88 149.53 170.88 103.15C170.89 99.0898 170.59 95.0898 170.03 91.1698ZM86.78 186.46C40.85 186.46 3.48999 149.09 3.48999 103.16C3.48999 57.2298 40.85 19.8698 86.78 19.8698C105.42 19.8698 122.64 26.0198 136.53 36.3998C141.14 39.8498 145.39 43.7598 149.19 48.0698C151.47 50.6498 153.59 53.3598 155.54 56.2098C162.56 66.4598 167.35 78.3498 169.2 91.1798C169.77 95.0998 170.07 99.0998 170.07 103.17C170.07 149.09 132.71 186.46 86.78 186.46Z"
        fill="black"
      />
      <path
        d="M159.66 100.65C159.55 97.44 159.23 94.27 158.72 91.17C157.92 86.38 156.66 81.74 154.97 77.3C154.05 74.87 153 72.51 151.83 70.21C146.9 60.51 139.85 52.06 131.31 45.45C119.59 36.38 105.06 30.8 89.29 30.28C87.66 30.24 85.95 30.24 84.32 30.28C46.27 31.55 15.43 62.26 13.94 100.27C13.93 100.4 13.93 100.52 13.92 100.65C13.9 101.47 13.88 102.3 13.88 103.14C13.88 103.97 13.9 104.81 13.92 105.63C13.93 105.77 13.93 105.9 13.94 106.03C15.43 143.89 46.05 174.51 83.91 176L84.32 176.02C85.14 176.04 85.97 176.06 86.81 176.06C87.64 176.06 88.48 176.04 89.3 176.02C89.42 176.01 89.55 176.01 89.68 176C127.69 174.52 158.4 143.68 159.67 105.62C159.69 104.8 159.71 103.97 159.71 103.14C159.7 102.3 159.68 101.47 159.66 100.65ZM89.67 175.19L89.26 175.21C89.12 175.22 88.99 175.22 88.85 175.22C87.49 175.25 86.07 175.25 84.71 175.22C84.59 175.22 84.46 175.21 84.33 175.21C84.18 175.2 84.04 175.2 83.9 175.19C46.49 173.7 16.23 143.44 14.74 106.03C14.73 105.89 14.72 105.74 14.72 105.6C14.72 105.47 14.71 105.35 14.71 105.22C14.7 104.54 14.68 103.84 14.68 103.14C14.68 102.45 14.7 101.76 14.71 101.08C14.72 100.94 14.72 100.81 14.72 100.67C14.73 100.53 14.73 100.4 14.74 100.26C16.22 62.83 46.48 32.57 83.9 31.1C84.04 31.09 84.19 31.08 84.33 31.08C85.14 31.06 85.97 31.04 86.8 31.04C87.63 31.04 88.46 31.06 89.26 31.08L89.67 31.1C105.15 31.7 119.4 37.23 130.9 46.16C139.27 52.66 146.19 60.96 151.05 70.47C152.25 72.8 153.32 75.21 154.25 77.69C155.89 82 157.12 86.51 157.9 91.17C158.41 94.15 158.72 97.18 158.84 100.27L158.86 100.68C158.88 101.49 158.9 102.31 158.9 103.15C158.9 103.98 158.88 104.81 158.86 105.61C158.85 105.76 158.85 105.9 158.84 106.04C157.36 143.45 127.1 173.71 89.67 175.19Z"
        fill="black"
      />
      <path
        d="M87.42 93.7402C82.24 93.7402 78.02 97.9602 78.02 103.15C78.02 104.69 78.39 106.15 79.05 107.43C79.95 109.19 81.39 110.62 83.14 111.52C84.42 112.19 85.88 112.56 87.42 112.56C89.27 112.56 91 112.02 92.46 111.09C94.12 110.04 95.42 108.48 96.15 106.64C96.59 105.56 96.83 104.38 96.83 103.15C96.83 97.9602 92.61 93.7402 87.42 93.7402ZM91.8 110.55C90.52 111.31 89.02 111.75 87.42 111.75C86.11 111.75 84.86 111.45 83.75 110.92C81.96 110.07 80.51 108.62 79.66 106.82C79.13 105.71 78.83 104.46 78.83 103.15C78.83 98.4102 82.68 94.5502 87.42 94.5502C92.16 94.5502 96.02 98.4102 96.02 103.15C96.02 104.18 95.84 105.18 95.5 106.1C94.81 107.97 93.49 109.54 91.8 110.55Z"
        fill="black"
      />
      <path
        d="M85.49 101.65C84.49 102.89 84.68 104.72 85.93 105.72L116.55 130.91C117.79 131.91 119.62 131.72 120.62 130.47C121.62 129.23 121.43 127.4 120.18 126.4L89.56 101.21C88.32 100.21 86.5 100.41 85.49 101.65Z"
        fill="#B7FA00"
      />
      <path
        d="M47.1 146.38C47.84 146.38 48.58 146.1 49.15 145.53L89.39 105.29C90.51 104.17 90.62 102.33 89.54 101.18C88.42 99.9902 86.54 99.9702 85.39 101.12L45.15 141.36C44 142.51 43.93 144.46 45.11 145.59C45.66 146.11 46.38 146.38 47.1 146.38Z"
        fill="#B7FA00"
      />
      <path
        d="M20.41 100.27H13.92L13.91 100.66C13.89 101.47 13.87 102.31 13.87 103.14C13.87 103.98 13.89 104.81 13.91 105.63L13.92 106.02H20.41C22 106.02 23.29 104.73 23.29 103.14C23.29 101.56 22 100.27 20.41 100.27ZM20.41 105.22H14.71C14.69 104.54 14.68 103.85 14.68 103.15C14.68 102.46 14.7 101.77 14.71 101.08H20.41C21.55 101.08 22.48 102.01 22.48 103.15C22.48 104.29 21.55 105.22 20.41 105.22Z"
        fill="black"
      />
      <path
        d="M89.27 30.2898C87.64 30.2498 85.93 30.2498 84.3 30.2898L83.91 30.2998V36.7698C83.91 38.3598 85.2 39.6498 86.79 39.6498C88.38 39.6498 89.67 38.3598 89.67 36.7698V30.2998L89.27 30.2898ZM88.86 36.7698C88.86 37.9098 87.93 38.8398 86.79 38.8398C85.65 38.8398 84.72 37.9098 84.72 36.7698V31.0898C86.08 31.0598 87.5 31.0598 88.86 31.0898V36.7698Z"
        fill="black"
      />
      <path
        d="M159.65 100.66L159.64 100.27H153.17C151.58 100.27 150.29 101.56 150.29 103.15C150.29 104.74 151.58 106.03 153.17 106.03H159.64L159.65 105.64C159.67 104.82 159.69 103.99 159.69 103.15C159.69 102.31 159.67 101.47 159.65 100.66ZM153.17 105.22C152.03 105.22 151.1 104.29 151.1 103.15C151.1 102.01 152.03 101.08 153.17 101.08H158.85C158.87 101.76 158.88 102.45 158.88 103.15C158.88 103.85 158.86 104.54 158.85 105.22H153.17Z"
        fill="black"
      />
      <path
        d="M86.79 166.65C85.2 166.65 83.91 167.94 83.91 169.53V176.02L84.3 176.03C85.12 176.05 85.95 176.07 86.79 176.07C87.62 176.07 88.46 176.05 89.27 176.03L89.66 176.02V169.53C89.67 167.94 88.38 166.65 86.79 166.65ZM84.72 169.53C84.72 168.39 85.65 167.46 86.79 167.46C87.93 167.46 88.86 168.39 88.86 169.53V175.23C87.5 175.27 86.08 175.27 84.72 175.23V169.53Z"
        fill="black"
      />
      <path
        d="M192.98 91.1699H112.44C109.1 91.1699 107.01 87.5499 108.68 84.6599L128.81 49.7799L130.9 46.1599L131.31 45.4499L136.54 36.3899L136.95 35.6899L148.95 14.8999C150.62 12.0099 154.8 12.0099 156.47 14.8999L176.6 49.7799L196.74 84.6599C198.4 87.5499 196.32 91.1699 192.98 91.1699Z"
        fill="#B7FA00"
      />
      <path
        d="M152.7 35.5703C150.53 35.5703 148.78 37.3303 148.78 39.5003V43.4903L149.07 46.7203L149.19 48.0703L150.55 63.0103C150.6 63.5803 150.86 64.0803 151.26 64.4403C151.65 64.8003 152.17 65.0203 152.75 65.0203C153.9 65.0203 154.85 64.1403 154.95 63.0003L155.53 56.2103L155.64 54.9403L156.62 43.4903V39.5003C156.63 37.3303 154.87 35.5703 152.7 35.5703ZM152.76 70.1303H152.64C152.36 70.1303 152.09 70.1603 151.82 70.2203C151.54 70.2803 151.28 70.3703 151.04 70.4803C150.6 70.6703 150.2 70.9503 149.87 71.2903C149.16 72.0003 148.72 72.9803 148.72 74.0703C148.72 76.2403 150.48 78.0003 152.64 78.0003H152.76C153.28 78.0003 153.79 77.9003 154.24 77.7103C154.49 77.6103 154.74 77.4803 154.96 77.3303C156 76.6203 156.68 75.4303 156.68 74.0803C156.69 71.8803 154.93 70.1303 152.76 70.1303Z"
        fill="white"
      />
    </svg>
  );
};

export default OverdueImage;
